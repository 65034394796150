<template>
  <div class="home_screen" v-if="profileType == 'Admin'">
    <v-container fluid class="mt-0 pt-0">
      <v-row class="mt-0 pt-0">
        <v-col cols="12" sm="6" md="9" class="mt-0 pt-0">
          <v-col
            cols="12"
            style="justify-content:space-between; flex-direction: row; display: flex; align-items: center;"
          >
            <label class="item_title">Distributed Analysis</label>
            <v-btn text small @click="goToDistributions" color="#747474">
              View all history >>
            </v-btn>
          </v-col>
          <v-card class="pt-10 px-3 pb-0 rounded-lg">
            <home-charts />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="mt-0 pt-0 d-flex flex-column">
          <div
            style="justify-content:space-between; flex-direction: row; display: flex; align-items: center;"
            class="pt-3 pb-3"
          >
            <label class="item_title">Events</label>
            <v-btn text small @click="goToCreateEvent" color="#747474">
              Add new events
            </v-btn>
          </div>
          <v-hover v-slot="{ hover }">
            <v-card
              class="pt-5 px-3 pb-0 rounded-lg px-5 d-flex flex-column"
              style="flex-grow: 1;"
              @click="goToEvents"
              :class="{ 'on-hover': hover, 'rounded-lg': true }"
            >
              <div style="flex-grow: 1">
                <div
                  v-for="event in events"
                  :key="event._id"
                  class="mb-4 d-flex"
                >
                  <div><v-img :src="event.cover" height="70" width="70" /></div>
                  <div
                    style="flex-grow: 1; padding-left: 10px; padding-right: 10px;"
                  >
                    <div
                      style="overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;"
                      class="url-button"
                      @click.stop="goToEventDetails(event._id)"
                      @mousedown.stop
                    >
                      {{ event.title }}
                    </div>
                    <div class="limited-text">
                      {{ extractContent(event.html) }}
                    </div>
                  </div>
                  <div style="font-size: 12px;">
                    {{ getDate(event.createdAt) }}
                  </div>
                </div>
              </div>
              <v-divider class="mx-7" />
              <v-card-actions style="justify-content:flex-end">
                <v-btn text x-small color="#7024C4" @click="goToEventHome">
                  View All
                  <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3" style="min-width: 240px">
          <v-sheet outlined color="white" height="100%" rounded>
            <v-hover v-slot="{ hover }">
              <v-card
                height="100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToClients"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Clients</label>
                  <label class="count_label ml-4" v-if="homeInfo.client">
                    {{ homeInfo.client }}
                    {{ homeInfo.client == 1 ? "account" : "accounts" }}
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between"
                >
                  <v-card class="mx-7 my-5" width="120">
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      Active
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 40px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.totalCount ? homeInfo.totalCount : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      People
                    </v-card-text>
                  </v-card>
                  <div style="width: 60px" class="mr-12">
                    <v-row class="users_image_section">
                      <v-col
                        cols="4"
                        v-for="(user, index) in user_images"
                        v-bind:key="index"
                      >
                        <editable-avatar
                          :image="user.image"
                          :editable="false"
                          :size="24"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToClients">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{
                  'on-hover': hover,
                  'rounded-lg': true,
                  'd-flex flex-column': true,
                }"
                @click="goToFreezer"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Freezers and Markets</label>
                  <label
                    class="count_label"
                    v-if="homeInfo.freezer && homeInfo.freezer.total"
                  >
                    {{ homeInfo.freezer.total }}
                    <!-- {{ homeInfo.freezer.total == 1 ? "member" : "members" }} -->
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between; flex-grow: 1"
                >
                  <v-card
                    class="mx-7 my-5"
                    width="120"
                    @click.stop="goToNewFreezers"
                  >
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      New
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 40px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.newFreezers ? homeInfo.newFreezers : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      Freezers
                    </v-card-text>
                  </v-card>
                  <div
                    class="mr-8 mt-8 d-flex flex-column"
                    style="align-items: flex-end;"
                  >
                    <div style="width: 50px;">
                      <v-img src="../assets/freezer.svg" contain />
                    </div>
                    <div v-if="homeInfo.freezer && homeInfo.freezer.market">
                      {{ homeInfo.freezer.market }}
                      {{ homeInfo.freezer.market == 1 ? "Market" : "Markets" }}
                    </div>
                    <div v-if="homeInfo.freezer && homeInfo.freezer.freezer">
                      {{ homeInfo.freezer.freezer }}
                      {{
                        homeInfo.freezer.freezer == 1
                          ? "Freezer Manager"
                          : "Freezer Managers"
                      }}
                    </div>
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToFoods">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{
                  'on-hover': hover,
                  'rounded-lg': true,
                  'd-flex flex-column': true,
                }"
                @click="goToPartners"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Partners</label>
                  <label class="count_label" v-if="homeInfo.organization">
                    {{ homeInfo.organization }}
                    {{
                      homeInfo.organization == 1
                        ? "organization"
                        : "organizations"
                    }}
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between; flex-grow: 1"
                >
                  <v-card
                    class="mx-7 my-5"
                    width="120"
                    @click.stop="goToNewPartners"
                  >
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      New
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 40px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.newOrganizations
                          ? homeInfo.newOrganizations
                          : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      Organization
                    </v-card-text>
                  </v-card>
                  <div
                    class="mr-8 mt-8 d-flex flex-column"
                    style="align-items: flex-start"
                    v-if="
                      homeInfo.organization_details &&
                        homeInfo.organization_details.organization_list
                    "
                  >
                    <div
                      v-for="item in homeInfo.organization_details
                        .organization_list"
                      :key="item._id"
                    >
                      {{ item._id }}: {{ item.count }}
                    </div>
                    <!-- <div
                      class="d-flex flex-row"
                      v-if="
                        homeInfo.organization_details &&
                          homeInfo.organization_details.food
                      "
                    >
                      <v-img
                        src="../assets/food.svg"
                        contain
                        width="25"
                        height="25"
                      />
                      Food: {{ homeInfo.organization_details.food }}
                    </div>
                    <div
                      class="d-flex flex-row"
                      v-if="
                        homeInfo.organization_details &&
                          homeInfo.organization_details.cloths
                      "
                    >
                      <v-img
                        src="../assets/cloths.svg"
                        contain
                        width="25"
                        height="25"
                      />
                      Clothing: {{ homeInfo.organization_details.cloths }}
                    </div>
                    <div
                      class="d-flex flex-row"
                      v-if="
                        homeInfo.organization_details &&
                          homeInfo.organization_details.dental
                      "
                    >
                      <v-img
                        src="../assets/dental.svg"
                        contain
                        width="25"
                        height="25"
                      />
                      Dental: {{ homeInfo.organization_details.dental }}
                    </div>
                    <div
                      class="d-flex flex-row"
                      v-if="
                        homeInfo.organization_details &&
                          homeInfo.organization_details.employees
                      "
                    >
                      <v-img
                        src="../assets/employee.svg"
                        contain
                        width="25"
                        height="25"
                      />
                      Employees: {{ homeInfo.organization_details.employees }}
                    </div> -->
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToPartners">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{
                  'on-hover': hover,
                  'rounded-lg': true,
                  'd-flex flex-column': true,
                }"
                @click="goToReferrals"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Referrals</label>
                  <label class="count_label" v-if="homeInfo.referral">
                    {{ homeInfo.referral }}
                    {{ homeInfo.referral == 1 ? "referral" : "referrals" }}
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between; flex-grow: 1"
                >
                  <v-card
                    class="mx-7 my-5"
                    width="120"
                    @click.stop="goToNewReferrals"
                  >
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      New
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 40px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.newReferrals ? homeInfo.newReferrals : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      referrals
                    </v-card-text>
                  </v-card>
                  <div class="mr-8 mt-8">
                    <v-img src="../assets/referrals.svg" contain />
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToReferrals">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
                <!-- <v-card-actions style="justify-content:flex-end">
                  <v-btn text color="#7024C4" x-small @click="goToReferrals">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToFoods"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Foods</label>
                  <label class="count_label" v-if="homeInfo.conversation">
                    {{ homeInfo.food }}
                    {{ homeInfo.food == 1 ? "food" : "foods" }}
                  </label>
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToFamily"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Chit Chat Family</label>
                  <label class="count_label" v-if="homeInfo.family">
                    {{ homeInfo.family }}
                    {{ homeInfo.family == 1 ? "family" : "families" }}
                  </label>
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToContactUS"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Contact Us</label>
                  <label class="count_label" v-if="homeInfo.conversation"
                    >{{ homeInfo.conversation }}
                    {{
                      homeInfo.conversation == 1
                        ? "conversation"
                        : "conversations"
                    }}</label
                  >
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../components/core/EditableAvatar.vue";
import HomeCharts from "../components/admin/HomeCharts.vue";
import dateFormat from "dateformat";

export default {
  name: "Home",
  components: {
    HomeCharts,
    EditableAvatar,
  },
  data() {
    return {
      user_images: [],
      homeInfo: {},
      events: [],
    };
  },
  methods: {
    ...mapActions({
      fetchHomeInfo: "auth/fetchHomeInfo",
      fetchAllEvents: "event/fetchAllEvents",
    }),
    goToClients() {
      this.$router.push({ name: "clientsHome" });
    },
    goToContactUS() {
      this.$router.push({ name: "chat" });
    },
    goToPartners() {
      this.$router.push({ name: "partnersHome" });
    },
    goToFoods() {
      this.$router.push({ name: "foods" });
    },
    goToFamily() {
      this.$router.push({ name: "families" });
    },
    goToEvents() {
      this.$router.push({ name: "event-home" });
    },
    goToReferrals() {
      this.$router.push({ name: "referrals" });
    },
    goToDistributions() {
      this.$router.push({ name: "distributions" });
    },
    goToCreateEvent() {
      this.$router.push({ name: "create-event" });
    },
    goToFreezer() {
      this.$router.push({ name: "freezers" });
    },
    goToNewClients() {
      this.$router.push({ name: "clientsHome", query: { new: true } });
    },
    goToNewPartners() {
      this.$router.push({ name: "partnersHome", query: { new: true } });
    },
    goToNewRequests() {
      this.$router.push({ name: "requests", query: { new: true } });
    },
    goToNewReferrals() {
      this.$router.push({ name: "referrals", query: { new: true } });
    },
    goToNewFreezers() {
      this.$router.push({ name: "freezers", query: { new: true } });
    },
    goToEventDetails(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
    goToEventHome() {
      this.$router.push({ name: "event-home" });
    },
    loadEvents() {
      this.loading = true;
      this.fetchAllEvents()
        .then((res) => {
          this.loading = false;
          this.events = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    getDate(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "mm/dd/yy");
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
  watch: {
    profileType(newValue) {
      if (newValue == "Partner") {
        this.$router.push({ name: "partner-home" });
      }
    },
  },
  mounted() {
    if (this.profileType == "Partner") {
      this.$router.push({ name: "partner-home" });
    } else if (this.profileType == "Freezer") {
      this.$router.push({ name: "freezer-home" });
    } else if (this.profileType == "Admin") {
      this.loading = true;
      this.fetchHomeInfo()
        .then((data) => {
          this.loading = false;
          this.homeInfo = { ...data };
          console.log(this.homeInfo);
          this.user_images = this.homeInfo.client_images;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.response.data.message);
          }
        });
      this.loadEvents();
    } else {
      this.$router.push({ name: "event-home" });
    }
  },
};
</script>
<style scoped>
.home_screen {
  background-color: #f4f9ff;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #423f63;
  font-size: 10px;
}
.item_title {
  color: #423f63;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.count_label {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  /* padding-left: 40px; */
  justify-content: center;
  max-width: 200px;
}
.on-hover {
  background-color: #f3e9ff !important;
}
</style>
