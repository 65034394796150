<template>
  <v-app-bar
    color="#7024c4"
    max-height="70"
    height="70"
    app
    fixed
    style="z-index: 12;"
  >
    <!-- <v-avatar
      class="elevation-6 ml-5"
      v-if="isAuthenticated"
      size="40"
      @click="onBackHome"
    > -->
    <div>
      <v-img
        src="@/assets/white_title_logo.svg"
        @click="onBackHome"
        height="30"
        width="240"
        class="ml-3 hidden-xs-only"
        contain
      />
      <v-img
        src="@/assets/white_logo.svg"
        @click="onBackHome"
        height="30"
        width="30"
        class="ml-3 hidden-sm-and-up"
        contain
      />
    </div>
    <!-- </v-avatar> -->
    <!-- <label class="nav_title" >
      Healthy<br />Communities</label
    > -->
    <div>
      <v-text-field
        hide-details
        label="Search"
        solo
        dense
        rounded
        outlined
        append-icon="mdi-magnify"
        v-if="isAuthenticated && isSearch"
        v-model="keyword"
        class="ml-10"
        @keypress.enter="onSearchEnter"
      />
    </div>
    <v-spacer></v-spacer>
    <div class="mr-3" v-if="isAuthenticated">
      <v-menu
        offset-y
        :close-on-content-click="false"
        v-model="notificationMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              :value="notifications.filter((n) => !n.read).length"
              overlap
              :content="notifications.filter((n) => !n.read).length"
            >
              <v-icon color="white">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <div style="background-color: white;">
          <v-list style="max-height: 80vh; overflow: auto;">
            <v-list-item class="app-bold-font font-20">
              Notification
            </v-list-item>
            <div v-if="notifications.length">
              <v-list-item>
                <v-btn rounded depressed>All</v-btn>
                <v-btn rounded class="ml-5" depressed>Unread</v-btn>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in notifications"
                :key="index"
                class="hover-item pb-2 pt-2"
                @click="onSelectedNotification(item)"
              >
                <v-list-item-title class="d-flex align-center w-100">
                  <div class="mr-2">
                    <v-img :src="item.image" width="40" height="40" />
                  </div>
                  <div class="wrap-word" style="width: calc(100% - 100px);">
                    {{ item.text }}
                  </div>
                  <div class="mr-1">
                    <v-menu offset-y rounded="lg" left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(menuItem, index) in notificationItemMenus"
                          v-on:change="
                            onSelectedNotificationMenu(menuItem, item)
                          "
                          :key="index"
                          link
                        >
                          <v-list-item-title class="mr-3">
                            <v-icon v-text="menuItem.icon" class="mr-3" />
                            {{ menuItem.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <div
                    style="width: 10px; height: 10px; background-color: blueviolet; border-radius: 5px;"
                    v-if="!item.read"
                  />
                </v-list-item-title>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item>
                <v-list-item-title>No notifications</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </div>
      </v-menu>
    </div>
    <div v-if="isAuthenticated" class="hidden-xs-only">
      <label class="name noselect ml-4" v-if="profile.city">{{
        (profile.city ? profile.city : "") +
          ", " +
          (profile.state ? profile.state : "")
      }}</label>
    </div>
    <v-avatar
      class="elevation-6 ml-5"
      v-if="isAuthenticated"
      size="40"
      @click="goToProfile"
    >
      <v-img :src="profile.image ? profile.image : placeholderImage" />
    </v-avatar>
    <div v-if="isAuthenticated" class="hidden-sm-and-down" @click="goToProfile">
      <label class="name noselect ml-4">{{
        (profile.first_name ? profile.first_name : "") +
          " " +
          (profile.last_name ? profile.last_name : "")
      }}</label>
    </div>
    <div class="text-center" v-if="isAuthenticated">
      <v-menu offset-y rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon color="white">
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menus"
            v-on:change="onSelectedMenu(index)"
            :key="index"
            link
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      menus: [{ title: "Logout" }],
      placeholderImage: require("@/assets/default.jpg"),
      isSearch: true,
      keyword: "",
      notificationItemMenus: [
        { title: "Mark as read", icon: "mdi-check" },
        { title: "Remove this notification", icon: "mdi-delete" },
      ],
      notificationMenu: false,
    };
  },
  methods: {
    ...mapActions({
      onLogout: "auth/onLogout",
      fetchProfile: "auth/fetchProfile",
      fetchAllNotifications: "notification/fetchAllNotifications",
      markAsRead: "notification/markAsRead",
      deleteNotification: "notification/deleteNotification",
    }),
    onSelectedMenu(index) {
      if (this.menus[index].title == "Settings") {
        if (this.profileType == "Partner") {
          this.$router.push({ name: "partner-account-settings" });
        } else if (this.profileType == "Admin") {
          this.$router.push({ name: "account-settings" });
        }
      } else if (this.menus[index].title == "Questions") {
        if (this.$route.name != "questions") {
          this.$router.push({ name: "questions" });
        }
      } else if (this.menus[index].title == "Foods") {
        if (this.$route.name != "foods") {
          this.$router.push({ name: "foods" });
        }
      } else if (this.menus[index].title == "Chat") {
        if (this.$route.name != "chat") {
          this.$router.push({ name: "chat" });
        }
      } else if (this.menus[index].title == "Chit Chat Family") {
        if (this.$route.name != "families") {
          this.$router.push({ name: "families" });
        }
      } else if (this.menus[index].title == "Create Event") {
        if (this.$route.name != "create-event") {
          this.$router.push({ name: "create-event" });
        }
      } else {
        this.onLogout().then(() => {
          this.$router.push({ name: "login" });
        });
      }
    },
    onSelectedNotificationMenu(index, item) {
      if (index.title == "Mark as read") {
        this.markAsRead({ _id: item._id })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (index.title == "Remove this notification") {
        this.deleteNotification({ _id: item._id })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onSelectedNotification(item) {
      if (item.action == "PartnerClientNot5Days") {
        this.markAsRead({ _id: item._id })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
        this.notificationMenu = false;
        let routeData = this.$router.resolve({
          name: "intake",
          params: { id: item.param1.split("-")[1] },
        });
        window.open(routeData.href, "_blank");
      } else if (item.action == "ClientOrderRequest") {
        if (this.$route.name == "freezer-distribution") {
          this.$router.replace({
            query: { request: item.param2.split("-")[1] },
          });
        } else {
          this.$router.push({
            name: "freezer-distribution",
            query: { request: item.param2.split("-")[1] },
          });
        }
        this.notificationMenu = false;
        this.deleteNotification({ _id: item._id })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onBackHome() {
      if (this.$route.name != "home") {
        this.$router.push("/");
      }
    },
    checkRoute() {
      console.log(this.$route.name);
      if (
        this.$route.name == null ||
        this.$route.name == "home" ||
        this.$route.name == "intake" ||
        this.$route.name == "distributions" ||
        this.$route.name == "freezer-details" ||
        this.$route.name == "requests" ||
        this.$route.name == "settings" ||
        this.$route.name == "account-settings" ||
        this.$route.name == "notification-settings" ||
        this.$route.name == "password-settings" ||
        this.$route.name == "variable-settings" ||
        this.$route.name == "freezer-home" ||
        this.$route.name == "freezer-distribution"
      ) {
        this.isSearch = false;
      } else if (
        this.$route.name == "referrals" ||
        this.$route.name == "clientsHome" ||
        this.$route.name == "freezers" ||
        this.$route.name == "partnersHome" ||
        this.$route.name == "foods" ||
        this.$route.name == "event-search"
      ) {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
      this.keyword = "";

      if (this.$route.name == "event-search") {
        this.keyword = this.$route.query.key;
      }

      this.fetchAllNotifications();
    },
    onSearchEnter() {
      if (this.$route.name == "event-search") {
        this.$store.commit("auth/setKeyword", this.keyword);
      }
    },
    goToProfile() {
      if (this.profileType == "User" && this.$route.name != "user-profile") {
        this.$router.push({ name: "user-profile" });
      }
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      notifications: "notification/getAllNotifications",
    }),
    // keyword: {
    //   get() {
    //     return this.$store.state.auth.keyword;
    //   },
    //   set(value) {
    //     if (this.$route.name != "event-search") {
    //       this.$store.commit("auth/setKeyword", value);
    //     }
    //   },
    // },
  },
  watch: {
    "$route.name": function() {
      this.checkRoute();
    },
    "$store.state.auth.keyword": function(newValue) {
      this.keyword = newValue;
    },
    keyword(newValue) {
      if (this.$route.name != "event-search") {
        this.$store.commit("auth/setKeyword", newValue);
      }
    },
  },
  mounted() {
    if (this.profileType === "Partner") {
      this.menus = [{ title: "Settings" }, { title: "Logout" }];
    } else if (this.profileType === "Freezer") {
      this.menus = [{ title: "Logout" }];
    } else if (this.profileType == "Admin") {
      this.menus = [
        { title: "Settings" },
        { title: "Foods" },
        { title: "Questions" },
        { title: "Chit Chat Family" },
        { title: "Create Event" },
        { title: "Chat" },
        { title: "Logout" },
      ];
    } else if (this.profileType == "User") {
      this.menus = [{ title: "Chat" }, { title: "Logout" }];
    }
    this.keyword = "";
    this.checkRoute();
    if (this.isAuthenticated) {
      this.loading = true;
      this.fetchProfile()
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            // this.tokenDialog = true;
            this.onLogout().then(() => {
              this.$router.push({ name: "login" });
            });
          } else {
            console.log(error);
          }
        });
      this.fetchAllNotifications();
    }
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #8e5ee3;
}

.vl {
  border-left: 4px solid rgba(242, 242, 242, 0.4);
  height: 50px;
  padding-left: 32px;
  padding-right: 25px;
}

.name {
  padding-top: 6px;
  padding-right: 10px;
  color: white;
}

.nav_title {
  color: white;
  padding-left: 20px;
  font-size: 15px;
  font-family: "Poppins-SemiBold";
}
</style>
