import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Landing from "../views/getStarted/Landing.vue";
import store from "@/store";

Vue.use(VueRouter);
const DEFAULT_TITLE = "Healthy Communities";
const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
    props: true,
    meta: { requiresAuth: false, title: "Healthy Communities" },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/partner/home",
    name: "partner-home",
    component: () =>
      import(
        /* webpackChunkName: "partner-home" */ "../views/partner/PartnerHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Partner"] },
  },
  {
    path: "/partner/referrals",
    name: "partner-referrals",
    component: () =>
      import(
        /* webpackChunkName: "partner-referrals" */ "../views/partner/PartnerReferrals.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Partner"] },
  },
  {
    path: "/partner/teammembers",
    name: "partner-teammembers",
    component: () =>
      import(
        /* webpackChunkName: "partner-teammembers" */ "../views/partner/PartnerTeamMembers.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Partner"] },
  },
  {
    path: "/partner/distribution",
    name: "partner-distribution",
    component: () =>
      import(
        /* webpackChunkName: "partner-distribution" */ "../views/partner/PartnerDistribution.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Partner"] },
  },
  {
    path: "/freezer/home",
    name: "freezer-home",
    component: () =>
      import(
        /* webpackChunkName: "freezer-home" */ "../views/freezer/FreezerHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Freezer"] },
  },
  {
    path: "/freezer/distribution",
    name: "freezer-distribution",
    component: () =>
      import(
        /* webpackChunkName: "freezer-distribution" */ "../views/freezer/FreezerDistribution.vue"
      ),
    props: true,
    meta: { requiresAuth: true, permission: ["Freezer"] },
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
      ),
    props: true,
  },
  {
    path: "/chat",
    name: "chat",
    meta: { requiresAuth: true, title: "Support | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/chat/Chat.vue"),
    children: [
      {
        path: "group",
        name: "group-chat",
        component: () => import("../views/chat/ChatGroupDetails.vue"),
      },
      {
        path: ":id",
        name: "chat-details",
        component: () => import("../views/chat/ChatDetails.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/mchat",
    name: "mchat-details",
    meta: { requiresAuth: true, title: "Support | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "mchat-details" */ "../views/chat/ChatMobileDetails.vue"
      ),
    props: true,
  },
  {
    path: "/clients",
    name: "clients",
    meta: { requiresAuth: true, title: "Clients | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "empty" */ "../views/Empty.vue"),
    children: [
      {
        path: "home",
        name: "clientsHome",
        meta: {
          requiresAuth: true,
          title: "Clients | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/admin/ClientsHome.vue"),
      },
      {
        path: "partner",
        name: "partnerClients",
        meta: {
          requiresAuth: true,
          title: "Clients | Healthy Communities",
          permission: ["Partner"],
        },
        component: () => import("../views/partner/PartnerClientsHome.vue"),
      },
      {
        path: "intake/:id",
        name: "intake",
        meta: {
          requiresAuth: true,
          title: "Intake | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/Intake.vue"),
      },
      {
        path: "checked-ins/:id",
        name: "checked-ins",
        meta: {
          requiresAuth: true,
          title: "Checked Ins | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/admin/ClientCheckedIns.vue"),
      },
      {
        path: "intake-view/:id",
        name: "intakeView",
        meta: {
          requiresAuth: true,
          title: "Intake | Healthy Communities",
          permission: ["Partner"],
        },
        component: () => import("../views/partner/PartnerIntake.vue"),
      },
      {
        path: "questions",
        name: "questions",
        meta: {
          requiresAuth: true,
          title: "Intake Questions | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/Questions.vue"),
      },
      {
        path: "tag-reports/:id",
        name: "tagReports",
        meta: {
          requiresAuth: true,
          title: "Tag Reports | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/admin/TagReport.vue"),
      },
      {
        path: "map",
        name: "clientMap",
        meta: {
          requiresAuth: true,
          title: "Client Map | Healthy Communities",
          permission: ["Admin"],
        },
        component: () => import("../views/admin/ClientMap.vue"),
      },
      // {
      //   path: "/*",
      //   redirect: "/clients/home",
      // },
    ],
    props: true,
  },
  {
    path: "/partners",
    name: "partners",
    meta: { requiresAuth: true, title: "Partners | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/Empty.vue"),
    children: [
      {
        path: "home",
        name: "partnersHome",
        meta: { requiresAuth: true, title: "Partners | Healthy Communities" },
        component: () => import("../views/admin/Partners.vue"),
      },
      {
        path: ":id",
        name: "freezer-details",
        meta: {
          requiresAuth: true,
          title: "Freezer Details | Healthy Communities",
        },
        component: () => import("../views/admin/FreezerDetails.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/freezers",
    name: "freezers",
    meta: {
      requiresAuth: true,
      title: "Freezer Managers | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "freezers" */ "../views/admin/Freezers.vue"),
    props: true,
  },
  {
    path: "/families",
    name: "families",
    meta: {
      requiresAuth: true,
      title: "Family Managers | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "families" */ "../views/admin/Families.vue"),
    props: true,
  },
  {
    path: "/foods",
    name: "foods",
    meta: {
      requiresAuth: true,
      title: "Food Management | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "foods" */ "../views/admin/Foods.vue"),
    props: true,
  },
  {
    path: "/goods",
    name: "partner-goods",
    meta: {
      requiresAuth: true,
      title: "Goods Management | Healthy Communities",
      permission: ["Partner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "foods" */ "../views/partner/PartnerGoods.vue"
      ),
    props: true,
  },
  {
    path: "/distributions",
    name: "distributions",
    meta: { requiresAuth: true, title: "Distributions | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "distributions" */ "../views/Distributions.vue"
      ),
    props: true,
  },
  {
    path: "/partner-distribution-history",
    name: "partner-distribution-history",
    meta: { requiresAuth: true, title: "Distributions | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "partner-distribution-history" */ "../views/partner/PartnerDistributionHistory.vue"
      ),
    props: true,
    permission: ["Partner"],
  },
  {
    path: "/partner-profile",
    name: "partner-profile",
    meta: {
      requiresAuth: true,
      title: "Profile | Healthy Communities",
      permission: ["Partner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "partner-profile" */ "../views/partner/PartnerProfileEdit.vue"
      ),
    props: true,
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: {
      requiresAuth: true,
      title: "Profile | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "user-profile" */ "../views/user/UserProfile.vue"
      ),
    props: true,
  },
  {
    path: "/select-interests",
    name: "select-interests",
    meta: {
      requiresAuth: true,
      title: "Profile | Healthy Communities",
      permission: ["User"],
    },
    component: () =>
      import(
        /* webpackChunkName: "select-interests" */ "../views/user/SelectInterests.vue"
      ),
    props: true,
  },
  // {
  //   path: "/requests",
  //   name: "requests",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Requests | Healthy Communities",
  //     permission: ["Admin"],
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "requests" */ "../views/Requests.vue"),
  //   props: true,
  // },
  {
    path: "/referrals",
    name: "referrals",
    meta: {
      requiresAuth: true,
      title: "Referrals | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "referrals" */ "../views/admin/Referrals.vue"
      ),
    props: true,
  },
  {
    path: "/referrals/add",
    name: "add-referral",
    meta: {
      requiresAuth: true,
      title: "Create New Referral | Healthy Communities",
      permission: ["Admin", "Partner"],
    },
    component: () =>
      import(/* webpackChunkName: "addreferral" */ "../views/AddReferral.vue"),
    props: true,
  },
  {
    path: "/event-home",
    name: "event-home",
    meta: {
      requiresAuth: false,
      title: "Events | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "event-home" */ "../views/events/EventHome.vue"
      ),
    props: true,
  },
  {
    path: "/create-event",
    name: "create-event",
    meta: {
      requiresAuth: true,
      title: "Create Event | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "create-event" */ "../views/events/CreateEvent.vue"
      ),
    props: true,
  },
  {
    path: "/edit-event",
    name: "edit-event",
    meta: {
      requiresAuth: true,
      title: "Event Details | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-event" */ "../views/events/EditEvent.vue"
      ),
    props: true,
  },
  {
    path: "/event-search",
    name: "event-search",
    meta: {
      requiresAuth: true,
      title: "Event Search | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "event-search" */ "../views/events/EventSearch.vue"
      ),
    props: true,
  },
  {
    path: "/event-details",
    name: "event-details",
    meta: {
      requiresAuth: false,
      title: "Event Details | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "event-details" */ "../views/events/EventDetails.vue"
      ),
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
      title: "Settings | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "questions" */ "../views/admin/Settings.vue"),
    children: [
      {
        path: "account",
        name: "account-settings",
        meta: {
          requiresAuth: true,
          title: "Account Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsAcount.vue"),
      },
      {
        path: "notification",
        name: "notification-settings",
        meta: {
          requiresAuth: true,
          title: "Notification Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsNotification.vue"),
      },
      {
        path: "password",
        name: "password-settings",
        meta: {
          requiresAuth: true,
          title: "Password Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsPassword.vue"),
      },
      {
        path: "variables",
        name: "variable-settings",
        meta: {
          requiresAuth: true,
          title: "Variable Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsVariables.vue"),
      },
      {
        path: "categories",
        name: "category-settings",
        meta: {
          requiresAuth: true,
          title: "Category Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsCategories.vue"),
      },
      {
        path: "images",
        name: "home-images",
        meta: {
          requiresAuth: true,
          title: "Home images | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsHomeImages.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/partner-settings",
    name: "partner-settings",
    meta: {
      requiresAuth: true,
      title: "Settings | Healthy Communities",
      permission: ["Partner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "../views/partner/PartnerSettings.vue"
      ),
    children: [
      {
        path: "account",
        name: "partner-account-settings",
        meta: {
          requiresAuth: true,
          title: "Account Settings | Healthy Communities",
        },
        component: () => import("../views/partner/PartnerSettingsAcount.vue"),
      },
      {
        path: "password",
        name: "partner-password-settings",
        meta: {
          requiresAuth: true,
          title: "Password Settings | Healthy Communities",
        },
        component: () => import("../views/partner/PartnerSettingsPassword.vue"),
      },
      {
        path: "variables",
        name: "partner-variable-settings",
        meta: {
          requiresAuth: true,
          title: "Variable Settings | Healthy Communities",
        },
        component: () =>
          import("../views/partner/PartnerSettingsVariables.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false, title: "Login | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/getStarted/Login.vue"),
    props: true,
  },
  {
    path: "/register",
    name: "register",
    meta: { requiresAuth: false, title: "Register | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/getStarted/Register.vue"
      ),
    props: true,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    meta: {
      requiresAuth: false,
      title: "Forgot Password | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/ForgotPassword.vue"
      ),
    props: true,
  },
  {
    path: "/verifycode",
    name: "verifycode",
    meta: {
      requiresAuth: false,
      title: "Verification Code | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/VerifyCode.vue"
      ),
    props: true,
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
      requiresAuth: false,
      title: "Reset Password | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/ResetPassword.vue"
      ),
    props: true,
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      requiresAuth: false,
      title: "Privacy and Policy | Healthy Communities",
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Policy.vue"),
    props: true,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    meta: {
      requiresAuth: false,
      title: "Terms of Use (EULA) | Healthy Communities",
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/TermsOfUse.vue"),
    props: true,
  },
  {
    path: "/404",
    name: "404",
    meta: { requiresAuth: false, title: "Not Found | Healthy Communities" },
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/clients",
    redirect: "/clients/home",
  },
  {
    path: "/partners",
    redirect: "/partners/home",
  },
  {
    path: "/linker",
    name: "linker",
    component: () =>
      import(/* webpackChunkName: "linker" */ "../views/Linker.vue"),
    props: true,
  },
  {
    path: "/*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters["auth/isAuthenticated"]) {
      if (
        to.meta.permission &&
        !to.meta.permission.includes(store.getters["auth/getType"])
      ) {
        router.push({ name: "home" });
      } else {
        if (from.name == "intake") {
          if (store.getters["intake/unsavedChanges"]) {
            const answer = window.confirm("Changes you made may not be saved.");
            if (answer) {
              next();
            }
          } else next();
        } else next();
        document.title = to.meta.title || DEFAULT_TITLE;
      }
    } else {
      next("login");
    }
  } else {
    next();
  }
});

export default router;
