import ApiManager from "@/api/ApiManager";

export default {
  namespaced: true,
  state: {
    referrals: [],
  },
  getters: {
    getAllReferrals(state) {
      return state.referrals;
    },
  },
  mutations: {
    setReferrals(state, data) {
      state.referrals = data;
    },
    addReferral(state, data) {
      const item = state.referrals.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.referrals.push(data);
      }
    },
    editReferral(state, data) {
      const item = state.referrals.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteReferral(state, data) {
      state.referrals = state.referrals.filter((q) => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllReferrals({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        ApiManager.getAllReferrals(
          {
            Authorization: rootState.auth.token,
          },
          (res) => {
            commit("setReferrals", res);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getReferral({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getReferralProfile(
          {
            Authorization: rootState.auth.token,
          },
          { _id },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addReferral({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addReferral(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (referral) => {
            commit("addReferral", referral);
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editReferral({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editReferral(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (referral) => {
            commit("editReferral", referral);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteReferral({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteReferral(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (referral) => {
            commit("deleteReferral", referral);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    setBalance({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.setBalance(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
};
