<template>
  <div style="position: relative">
    <highcharts :options="chartOptions" style="height:280px" ref="chart" />
    <div class="chartOption">
      <v-select
        v-model="dataType"
        @input="onChangeType"
        dense
        :items="dataTypeOptions"
        outlined
        hide-details
        class="mb-2 mt-1"
        v-if="profileType == 'Admin'"
      />
      <v-select
        v-model="chartKey"
        @input="onChange"
        dense
        :items="keyOptions"
        outlined
        class="mt-1"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      chartOptions: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // type: "area",
          spacing: [20, 30, 20, 20],
        },
        credits: {
          enabled: false,
        },
        title: {
          text: " ", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        plotOptions: {
          column: {
            depth: 25,
          },
          // series: {
          //   color: "#7024C4",
          // },
        },
        xAxis: {
          labels: {
            formatter: function() {
              let myDate = new Date(this.value);
              return moment(String(myDate)).format("YYYY/MMM");
              // return moment(String(myDate)).format("MMM DD");
            },
          },
        },
        yAxis: {
          title: "",
        },
        series: [
          {
            showInLegend: true,
            name: "Fruits",
            data: [],
          },
          {
            showInLegend: true,
            name: "Vegetables",
            data: [],
          },
          {
            showInLegend: true,
            name: "Grains",
            data: [],
          },
          {
            showInLegend: true,
            name: "Protein",
            data: [],
          },
          {
            showInLegend: true,
            name: "Dairy",
            data: [],
          },
        ],
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        tooltip: {
          useTHML: true,
          formatter: function() {
            let myDate = new Date(this.x);
            // console.log(this);
            return (
              // moment(String(myDate)).format("MMM DD") +
              moment(String(myDate)).format("YYYY MMM") +
              "<br>" +
              this.series.name +
              `: <span style="font-weight:bold">` +
              this.y +
              "</span>"
            );
          },
        },
      },
      loading: false,
      keyOptions: ["Month", "Year"],
      chartKey: "Month",
      dataType: "Total", //"Distributed"
      dataTypeOptions: ["Distributed", "Total"],
    };
  },
  methods: {
    ...mapActions("auth", {
      getDistributionList: "getDistributionList",
      getTotalDistributionList: "getTotalDistributionList",
    }),
    onChange() {
      const chartKey = this.chartKey;
      this.chartOptions.xAxis.labels.formatter = function() {
        let myDate = new Date(this.value);
        if (chartKey == "Month") {
          return moment(String(myDate)).format("YYYY MMM");
        } else if (chartKey == "Year") {
          return moment(String(myDate)).format("YYYY");
        }
        return moment(String(myDate)).format("MMM DD");
      };

      this.chartOptions.tooltip.formatter = function() {
        let myDate = new Date(this.x);
        if (chartKey == "Month") {
          return (
            moment(String(myDate)).format("YYYY MMM") +
            "<br>" +
            this.series.name +
            `: <span style="font-weight:bold">` +
            this.y +
            "</span>"
          );
        } else if (chartKey == "Year") {
          return (
            moment(String(myDate)).format("YYYY") +
            "<br>" +
            this.series.name +
            `: <span style="font-weight:bold">` +
            this.y +
            "</span>"
          );
        }
        return (
          moment(String(myDate)).format("MMM DD") +
          "<br>" +
          this.series.name +
          `: <span style="font-weight:bold">` +
          this.y +
          "</span>"
        );
      };

      this.onLoad();
    },
    onChangeType() {
      if (this.dataType == "Distributed") {
        this.keyOptions = ["Day", "Month", "Year"];
      } else if (this.dataType == "Total") {
        this.keyOptions = ["Month", "Year"];
        if (this.chartKey == "Day") this.chartKey = "Month";
      }
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      this.$refs.chart.chart.showLoading();
      if (this.dataType == "Distributed") {
        this.getDistributionList({ key: this.chartKey })
          .then((res) => {
            this.loading = false;
            var fruitsData = [];
            var vegetablesData = [];
            var grainsData = [];
            var proteinData = [];
            var dairyData = [];
            res.map((d) => {
              let stamp = new Date(d._id).getTime();
              if (d._id.length == 7) {
                let tDate = new Date(d._id + "-1");
                let nDate = new Date(tDate.setMonth(tDate.getMonth() + 1));
                let sDate = new Date(nDate.setDate(nDate.getDate() - 1));
                let today = new Date();
                if (today < sDate) {
                  stamp = today.getTime();
                } else {
                  stamp = new Date(sDate).getTime();
                }
              }

              fruitsData.push([stamp, d.fruits]);
              vegetablesData.push([stamp, d.vegetables]);
              grainsData.push([stamp, d.grains]);
              proteinData.push([stamp, d.protein]);
              dairyData.push([stamp, d.dairy]);
            });
            this.$refs.chart.chart.hideLoading();
            // Enable when needed to show real data
            this.chartOptions.series[0].data = fruitsData;
            this.chartOptions.series[1].data = vegetablesData;
            this.chartOptions.series[2].data = grainsData;
            this.chartOptions.series[3].data = proteinData;
            this.chartOptions.series[4].data = dairyData;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
            this.$refs.chart.chart.hideLoading();
          });
      } else {
        this.getTotalDistributionList({ key: this.chartKey })
          .then((res) => {
            this.loading = false;
            var fruitsData = [];
            var vegetablesData = [];
            var grainsData = [];
            var proteinData = [];
            var dairyData = [];
            res.map((d) => {
              let stamp = new Date(d._id).getTime();
              if (d._id.length == 7) {
                let tDate = new Date(d._id + "-1");
                let nDate = new Date(tDate.setMonth(tDate.getMonth() + 1));
                let sDate = new Date(nDate.setDate(nDate.getDate() - 1));
                let today = new Date();
                if (today < sDate) {
                  stamp = today.getTime();
                  console.log(today);
                } else {
                  stamp = new Date(sDate).getTime();
                }
              }
              fruitsData.push([stamp, d.fruits]);
              vegetablesData.push([stamp, d.vegetables]);
              grainsData.push([stamp, d.grains]);
              proteinData.push([stamp, d.protein]);
              dairyData.push([stamp, d.dairy]);
            });
            this.$refs.chart.chart.hideLoading();
            // Enable when needed to show real data
            this.chartOptions.series[0].data = fruitsData;
            this.chartOptions.series[1].data = vegetablesData;
            this.chartOptions.series[2].data = grainsData;
            this.chartOptions.series[3].data = proteinData;
            this.chartOptions.series[4].data = dairyData;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
            this.$refs.chart.chart.hideLoading();
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
  mounted() {
    this.onLoad();
  },
};
</script>
<style scoped>
.chartOption >>> .v-select__selection {
  font-size: 12px;
  font-weight: bold;
}
.chartOption {
  position: absolute;
  top: 120px;
  right: 10px;
  width: 119px;
}
</style>
