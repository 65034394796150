import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    clients: [],
  },
  getters: {
    getAllClients(state) {
      return state.clients;
    },
  },
  mutations: {
    setClients(state, data) {
      state.clients = data;
    },
    addClient(state, data) {
      const item = state.clients.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.clients.push(data);
      }
    },
    editClient(state, data) {
      const item = state.clients.find((item) => item._id === data._id);
      if (item) Object.assign(item, data);
    },
    deleteClient(state, data) {
      state.clients = state.clients.filter((q) => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllClients({ commit, rootState }) {
      if (rootState.auth.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.getAllClients(
            {
              Authorization: rootState.auth.token,
            },
            (res) => {
              commit("setClients", res);
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } else if (rootState.auth.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("clients/organization")
            .then((res) => {
              if (res.data.success == true) {
                commit("setClients", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        return "Invalid";
      }
    },
    getClient({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getClient(
          {
            Authorization: rootState.auth.token,
          },
          {
            _id,
          },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addClient({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addClient(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (client) => {
            commit("addClient", client);
            resolve(client);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editClient({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editClient(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (client) => {
            commit("editClient", client);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    checkInClient({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/check-in", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteClient({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteClient(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (client) => {
            commit("deleteClient", client);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteClientImage({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/delete-profile-image", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editClient", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getHistories({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("history/client", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setBalance({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/set-balance", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getExcelJsonData({ rootState }, params) {
      const res = await axiosInstance(rootState.auth.token).post(
        "clients/excel-json",
        params
      );
      return res.data;
    },
    syncWithOneCiti({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/sync-oneciti")
          .then((res) => {
            if (res.data.success == true) {
              commit("setClients", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendVerificationCode({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/send-verification-code", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    verifyPhone({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/verify-phone", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkedInSearch({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/checked-in-search", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadClientMap({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/map", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
