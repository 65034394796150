import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    goods: [],
  },
  getters: {
    getAllGoods(state) {
      return state.goods;
    },
  },
  mutations: {
    setGoods(state, data) {
      state.goods = data;
    },
    addGood(state, data) {
      const item = state.goods.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.goods.push(data);
      }
    },
    editGood(state, data) {
      if (state.goods.length == 0) {
        state.goods.push(data);
      }
      const item = state.goods.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteGood(state, data) {
      state.goods = state.goods.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllGoods({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/all", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setGoods", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAvailableGoods({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/availables", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setGoods", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getGood({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addGood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addGood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editGood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/update", param)
          .then((res) => {
            if (res.data.success == true) {
              console.log("editgood", res.data.res);
              commit("editGood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteGood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("goods/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteGood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
