import ApiManager from "@/api/ApiManager";

export default {
  namespaced: true,
  state: {
    questions: [],
    sections: [],
    dragging: false,
    selectedQuestion: {
      _id: -1,
      key: "",
    },
    medical: false,
  },
  getters: {
    getAllQuestions(state) {
      return state.questions;
    },
    getSelectedQuestion(state) {
      return state.selectedQuestion;
    },
    getMedical(state) {
      return state.medical;
    },
  },
  mutations: {
    setQuestions(state, data) {
      state.questions = data;
      state.sections = state.questions
        .filter((q) => q.form_field == "Section")
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
    addQuestion(state, data) {
      const item = state.questions.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.questions.push(data);
      }
      state.sections = state.questions.filter((q) => q.form_field == "Section");
    },
    editQuestion(state, data) {
      const item = state.questions.find((item) => item._id === data._id);
      Object.assign(item, data);
      state.sections = state.questions.filter((q) => q.form_field == "Section");
    },
    deleteQuestion(state, data) {
      state.questions = state.questions.filter((q) => q._id !== data._id);
      state.sections = state.questions.filter((q) => q.form_field == "Section");
    },
  },
  actions: {
    fetchAllQuestions({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        ApiManager.getAllQuestions(
          {
            Authorization: rootState.auth.token,
          },
          (res) => {
            commit("setQuestions", res);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addSection({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addQuestion(
          {
            Authorization: rootState.auth.token,
          },
          { ...params, form_field: "Section" },
          (section) => {
            commit("addQuestion", section);
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    addQuestion({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addQuestion(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (section) => {
            commit("addQuestion", section);
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editQuestion({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editQuestion(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (question) => {
            commit("editQuestion", question);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteQuestion({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.deleteQuestion(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (question) => {
            commit("deleteQuestion", question);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    startDragging({ state }) {
      state.dragging = true;
    },
    endDragging({ state }) {
      state.dragging = false;
    },
    selectQuestion({ state }, param) {
      state.selectedQuestion = param;
    },
    setMedical({ state }, param) {
      state.medical = param;
    },
  },
};
