import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    intakes: {},
    selectedIntake: {},
    selectedId: "",
    selectedClientId: "",
    unsavedChanges: false,
  },
  getters: {
    getAllIntakes: (state) => state.intakes,
    getSelectedIntake(state) {
      return state.selectedIntake;
    },
    selectedId(state) {
      return state.selectedId;
    },
    selectedClientId(state) {
      return state.selectedClientId;
    },
    unsavedChanges(state) {
      return state.unsavedChanges;
    },
  },
  mutations: {
    setIntakes(state, data) {
      var intakes = {};
      data.map((d) => {
        intakes[`${d.question_id}_${d.index}`] = d.value;
        return true;
      });
      state.intakes = intakes;
    },
    setUnsavedChanges(state, data) {
      state.unsavedChanges = data;
    },
  },
  actions: {
    fetchAllIntakes({ commit, rootState, state }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/all", { ...param, client_id: state.selectedId })
          .then((res) => {
            if (res.data.success == true) {
              commit("setIntakes", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchFamilyIntakes({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/family", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFamilyFiltered({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/family-filtered", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getClientFamilyFiltered({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/family-client-filtered", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveIntake({ commit, rootState, state }) {
      var data = Object.keys(state.intakes).map((key) => {
        var arr = key.split("_");
        return {
          question_id: arr[0],
          index: arr[1],
          value: state.intakes[key],
        };
      });
      return new Promise((resolve, reject) => {
        ApiManager.saveIntake(
          {
            Authorization: rootState.auth.token,
          },
          { data: data, client_id: state.selectedId },
          (res) => {
            commit("setUnsavedChanges", false);
            resolve(res);
          },
          (error) => {
            commit("setUnsavedChanges", false);
            reject(error);
          }
        );
      });
    },
    saveIntakeWithoutCalc({ commit, rootState, state }) {
      var data = Object.keys(state.intakes).map((key) => {
        var arr = key.split("_");
        return {
          question_id: arr[0],
          index: arr[1],
          value: state.intakes[key],
        };
      });
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/save", { data: data, client_id: state.selectedId })
          .then((res) => {
            if (res.data.success == true) {
              commit("setUnsavedChanges", false);
              resolve(res.data.res);
            } else {
              commit("setUnsavedChanges", false);
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    doNotQualify({ rootState, state }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("clients/qualified", {
            client_id: state.selectedId,
            qualified: param,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadDocumentFile({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/upload-file", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    moveUpChild({ commit, rootState, state }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/move-up-child", {
            ...param,
            client_id: state.selectedId,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("setIntakes", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    moveDownChild({ commit, rootState, state }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/move-down-child", {
            ...param,
            client_id: state.selectedId,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("setIntakes", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteChild({ commit, rootState, state }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/remove-child", {
            ...param,
            client_id: state.selectedId,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("setIntakes", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setIntake({ state }, param) {
      state.intakes = {
        ...(state?.intakes || {}),
        [`${param._id}_${param.index}`]: param.value,
      };
    },
    setUnsavedChanges({ commit }) {
      commit("setUnsavedChanges", true);
    },
    setSelectedId({ state }, param) {
      state.selectedId = param;
    },
    setSelectedClientId({ state }, param) {
      state.selectedClientId = param;
    },
    selectQuestion({ state }, param) {
      state.selectedQuestion = param;
    },
    beforeBirth({ rootState, state }, index) {
      var monthQuestion = rootState.question.questions.filter((q) => {
        q.key == "birth_month";
      });
      if (monthQuestion.length == 0) {
        return false;
      }
      monthQuestion = monthQuestion[0];
      var date = new Date();
      var currentMonth = date.getMonth();
      var month = parseInt(state.intakes[`${monthQuestion._id}_${index}`]);
      if (month < currentMonth) {
        return true;
      }
      return false;
    },
    getTotalWIC({ rootState, state }) {
      var total = 0;
      var food = 0;
      var enabledWHS = false;
      for (var i = 0; i < rootState.question.questions.length; i++) {
        var q = rootState.question.questions[i];
        if (
          q.key == "how_much_do_you_receive_monthly_from_wic_" ||
          q.key == "how_much_do_you_receive_monthly_from_hip_" ||
          q.key == "how_much_do_you_receive_monthly_from_snap_" ||
          q.key == "what_is_your_food_budget_"
        ) {
          total += parseInt(
            state.intakes[`${q._id}_0`] ? state.intakes[`${q._id}_0`] : "0"
          );
        }
        if (q.key == "what_is_your_food_budget_")
          food = parseInt(
            state.intakes[`${q._id}_0`] ? state.intakes[`${q._id}_0`] : "0"
          );
        if (
          q.key == "do_you_have_wic_hip_or_snap_" &&
          state.intakes[`${q._id}_0`] == "true"
        ) {
          enabledWHS = true;
        }
      }
      if (enabledWHS) return `${total}`;
      else return `${food}`;
    },
    getBMI({ rootState, state }, index) {
      let height = 0;
      let weight = 0;
      for (let i = 0; i < rootState.question.questions.length; i++) {
        let q = rootState.question.questions[i];

        if (q.key == "height")
          height = parseFloat(
            state.intakes[`${q._id}_${index}`]
              ? state.intakes[`${q._id}_${index}`]
              : "0"
          );
        if (q.key == "weight") {
          weight = parseFloat(
            state.intakes[`${q._id}_${index}`]
              ? state.intakes[`${q._id}_${index}`]
              : "0"
          );
        }
      }
      if (!height || !weight) return null;
      return Math.round((weight / height / height) * 703 * 100) / 100;
    },
    getAgeData({ rootState, state }, index) {
      for (let i = 0; i < rootState.question.questions.length; i++) {
        let q = rootState.question.questions[i];

        if (q.key == "birthday") {
          const value = state.intakes[`${q._id}_${index}`];
          if (!value) return 0;
          var today = new Date();
          var birthDate = new Date(value);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }

          return age;
        }
      }
      return 0;
    },
    getNeedDays({ state, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/need-days", { client_id: state.selectedId })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    nameSearches({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("intakes/names", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
