import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    freezers: [],
    balances: [],
  },
  getters: {
    getAllFreezers(state) {
      return state.freezers;
    },
    getAllBalances(state) {
      return state.balances;
    },
  },
  mutations: {
    setFreezers(state, data) {
      state.freezers = data;
    },
    addFreezer(state, data) {
      const item = state.freezers.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.freezers.unshift(data);
      }
    },
    editFreezer(state, data) {
      if (state.freezers.length == 0) {
        state.freezers.push(data);
      }
      const item = state.freezers.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteFreezer(state, data) {
      state.freezers = state.freezers.filter((q) => q._id !== data._id);
    },
    setBalances(state, data) {
      state.balances = data;
    },
    addBalance(state, data) {
      const item = state.balances.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.balances.push(data);
      }
    },
    editBalance(state, data) {
      if (state.balances.length == 0) {
        state.balances.push(data);
      }
      const item = state.balances.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteBalance(state, data) {
      state.balances = state.balances.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllFreezers({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("freezers/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setFreezers", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFreezer({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("freezers/get", { _id: param })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addFreezer({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("freezers/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addFreezer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editFreezer({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("freezers/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editFreezer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFreezer({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("freezers/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteFreezer", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllBalances({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setBalances", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllFreezerBalances({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/freezer", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("setBalances", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBalance({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/get", { _id: param })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
