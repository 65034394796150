import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    getAllEvents(state) {
      return state.events;
    },
  },
  mutations: {
    setEvents(state, data) {
      state.events = data;
    },
    addEvent(state, data) {
      const item = state.events.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.events.unshift(data);
      }
    },
    editEvent(state, data) {
      const item = state.events.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.events.push(data);
      }
    },
    deleteEvent(state, data) {
      state.events = state.events.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    createEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/create", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addEvent", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadEventImage({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/upload-image", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllEvents({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/all", params)
          .then((res) => {
            if (res.data.success == true) {
              // commit("setEvents", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchUserEvents({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/user-events", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllEventsCount({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/count", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchUserEventsCount({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/user-events-count", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editEvent", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteEvent", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEventDetails({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("events/get", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
