import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    employees: [],
    organizations: [],
    balances: [],
  },
  getters: {
    getAllPartners(state) {
      return state.employees;
    },
    getAllOrganizations(state) {
      return state.organizations;
    },
    getAllBalances(state) {
      return state.balances;
    },
  },
  mutations: {
    setEmployees(state, data) {
      state.employees = data;
    },
    setOrganizations(state, data) {
      state.organizations = data;
    },
    addEmployee(state, data) {
      const item = state.employees.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.employees.push(data);
      }
      state.organizations = state.organizations.map((o) => {
        if (data.organization == o._id) {
          o.employees.push(data._id);
        }
        return o;
      });
    },
    editEmployee(state, data) {
      const item = state.employees.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.employees.push(data);
      }
    },
    deleteEmployee(state, data) {
      state.employees = state.employees.filter((q) => q._id !== data._id);
    },
    addOrganization(state, data) {
      const item = state.organizations.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.organizations.push(data);
      }
    },
    editOrganization(state, data) {
      const item = state.organizations.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.organizations.push(data);
      }
    },
    deleteOrganization(state, data) {
      state.organizations = state.organizations.filter(
        (q) => q._id !== data._id
      );
    },
    setBalances(state, data) {
      state.balances = data;
    },
    addBalance(state, data) {
      const item = state.balances.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.balances.push(data);
      }
    },
    editBalance(state, data) {
      if (state.balances.length == 0) {
        state.balances.push(data);
      }
      const item = state.balances.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteBalance(state, data) {
      state.balances = state.balances.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllOrganizations({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setOrganizations", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getOrganization({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addOrganization({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addOrganization", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editOrganization({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editOrganization", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteOrganization({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteOrganization", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchEmployees({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partners/employees", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("setEmployees", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchTeamMembers({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partners/teammembers")
          .then((res) => {
            if (res.data.success == true) {
              commit("setEmployees", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPartner({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        ApiManager.getPartnerProfile(
          {
            Authorization: rootState.auth.token,
          },
          { _id },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addEmployee({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partners/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addEmployee", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editEmployee({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.editPartner(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (partner) => {
            commit("editEmployee", partner);
            resolve(partner);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteEmployee({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partners/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteEmployee", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEmployeeClients({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/clients", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addClientToOrganization({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/add-client", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteClientFromOrganization({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("organizations/remove-client", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setBalance({ rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.setBalance(
          {
            Authorization: rootState.auth.token,
          },
          params,
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchAllPartnerBalances({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/partner", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("setBalances", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBalance({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/get", { _id: param })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/add-partner-balance", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/update-partner-balance", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteBalance({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/delete-partner-balance", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteBalance", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
