import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import client from "./modules/client";
import question from "./modules/question";
import intake from "./modules/intake";
import partner from "./modules/partner";
import request from "./modules/request";
import family from "./modules/family";
import referral from "./modules/referral";
import distribution from "./modules/distribution";
import chat from "./modules/chat";
import variable from "./modules/variable";
import freezer from "./modules/freezer";
import food from "./modules/food";
import good from "./modules/good";
import event from "./modules/event";
import user from "./modules/user";
import filter from "./modules/filter";
import notification from "./modules/notification";
import checkedin from "./modules/checkedin";
import promo_code from "./modules/prome_code";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    client,
    event,
    partner,
    freezer,
    user,
    question,
    intake,
    request,
    chat,
    referral,
    distribution,
    variable,
    food,
    good,
    filter,
    notification,
    family,
    checkedin,
    promo_code,
  },
  state: {},
  mutations: {},
  actions: {},
});
