import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  actions: {
    editUser({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/update", params)
          .then((res) => {
            if (res.data.success == true) {
              if (res.data.res._id == rootState.auth.profile._id) {
                commit("auth/setProfile", res.data.res, { root: true });
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUser({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("users/get", { _id })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
