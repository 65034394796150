import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    distributions: [],
  },
  getters: {
    getAllDistributions(state) {
      return state.distributions;
    },
  },
  mutations: {
    setDistributions(state, data) {
      state.distributions = data;
    },
    addDistribution(state, data) {
      const item = state.distributions.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.distributions.push(data);
      }
    },
    editDistribution(state, data) {
      const item = state.distributions.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteDistribution(state, data) {
      state.distributions = state.distributions.filter(
        (q) => q._id !== data._id
      );
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllDistributions({ commit, rootState }, params) {
      if (rootState.auth.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("distribution/all", params)
            .then((res) => {
              if (res.data.success == true) {
                commit("setDistributions", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Freezer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("distribution/freezer", params)
            .then((res) => {
              if (res.data.success == true) {
                commit("setDistributions", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("distribution/partner", params)
            .then((res) => {
              if (res.data.success == true) {
                commit("setDistributions", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    clientSummary({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/summary", {
            _id,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDistribution({ rootState }, _id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/get", {
            _id,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addDistribution({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addDistribution", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editDistribution({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/edit", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editDistribution", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteDistribution({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteDistribution", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTagReports({ rootState }, params) {
      console.log(params);
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/tag-reports", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllTagReports({ rootState }, params) {
      console.log(params);
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("distribution/all-tag-reports", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    distribute({ rootState }, params) {
      if (rootState.auth.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("admin/distribute", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Freezer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("freezers/distribute", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Partner") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("partners/distribute", params)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    partnerDistribute({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("partners/distribute", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
