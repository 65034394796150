import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    foods: [],
  },
  getters: {
    getAllFoods(state) {
      return state.foods;
    },
  },
  mutations: {
    setFoods(state, data) {
      state.foods = data;
    },
    addFood(state, data) {
      const item = state.foods.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.foods.push(data);
      }
    },
    editFood(state, data) {
      if (state.foods.length == 0) {
        state.foods.push(data);
      }
      const item = state.foods.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteFood(state, data) {
      state.foods = state.foods.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllFoods({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/all", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setFoods", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAvailableFoods({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/availables", param)
          .then((res) => {
            if (res.data.success == true) {
              if (!param) {
                commit("setFoods", res.data.res);
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFood({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addFood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/add", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("addFood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editFood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/update", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("editFood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFood({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("foods/delete", param)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteFood", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBalanceDistribution({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("balances/food", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
