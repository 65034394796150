import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    families: [],
  },
  getters: {
    getAllFamilies(state) {
      return state.families;
    },
  },
  mutations: {
    setFamilies(state, data) {
      state.families = data;
    },
    addFamily(state, data) {
      const item = state.families.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.families.push(data);
      }
    },
    editFamily(state, data) {
      const item = state.families.find((item) => item._id === data._id);
      Object.assign(item, data);
    },
    deleteFamily(state, data) {
      state.families = state.families.filter((q) => q._id !== data._id);
    },
    setStreets(state, data) {
      state.streets = data;
    },
    setStreetList(state, data) {
      state.streetList = data;
    },
  },
  actions: {
    fetchAllFamilies({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("family/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setFamilies", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFamily({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("family/get", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setFamilies({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("family/set", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addFamily({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.addFamily(
          {
            Authorization: rootState.auth.token,
          },
          params,
          (family) => {
            commit("addFamily", family);
            resolve();
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
    editFamily({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("family/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editFamily", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFamily({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("family/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteFamily", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    isEnabledPromoCode({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/is-enabled-promo-code")
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setEnabledPromoCode({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("settings/enabled-promo-code", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
